import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "@app/shared/components/roam-loader/loader/loader.component";
import { LoaderDotComponent } from "@app/shared/components/roam-loader/loader-dot/loader-dot.component";
import { LoaderTypingComponent } from "@app/shared/components/roam-loader/loader-typing/loader-typing.component";

@NgModule({
  imports: [
    CommonModule,
    LoaderComponent,
    LoaderTypingComponent,
    LoaderDotComponent,
  ],
  exports: [
    LoaderComponent,
    LoaderDotComponent,
    LoaderTypingComponent,
    LoaderDotComponent,
  ],
})
export class RoamLoaderModule {}
