<div
  class="loading-container"
  [class.light]="light"
  [style.justifyContent]="position">
  @for (dot of [0, 1, 2]; track $index) {
    <div
      class="dot"
      [style.width]="size + 'px'"
      [style.height]="size + 'px'"></div>
  }
</div>
