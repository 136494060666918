import { Component, input, Input, numberAttribute } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-loader-dot",
  templateUrl: "./loader-dot.component.html",
  styleUrls: ["./loader-dot.component.scss"],
})
export class LoaderDotComponent {
  @Input()
  public color: string = "#C1C1C1";

  readonly size = input(10, { transform: numberAttribute });

  @Input()
  public light: boolean = false;

  @Input()
  public position: string = "";
}
